import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import queryReducer from "./query/querySlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    query: queryReducer,
  },
});

export default store;
