import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {},
});

export const {} = querySlice.actions;
export default querySlice.reducer;
