import React, { useEffect } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { disableInitialLoading, setReportData } from "../../redux/app/appSlice";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  mainSection: {
    transition: "padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    "&.noSidebar": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "20px",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
  },
}));

const MainLayout = ({}) => {
  const { initialAppLoading } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(disableInitialLoading(false));
  }, []);

  const classes = useStyles();
  return (
    <>
      <Backdrop open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {!initialAppLoading && (
        <div className={classes.content}>
          <div
            className={clsx({
              [classes.mainSection]: true,
            })}
          >
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
